.gradient-text {
    background-image: linear-gradient(176deg, #2630c0, #0e131f);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.gradiant-01 {
    position: absolute;
    width: 500px;
    height: 500px;
    left: -5%;
    top: 35%;

    background: rgba(10, 51, 233, 0.451);
    filter: blur(120px);
}

.gradiant-02 {
    position: absolute;
    width: 300px;
    height: 500px;
    right: 10%;
    top: 300%;

    background: #1178d869;
    filter: blur(120px);
    transform: rotate(90.2deg);
}

.gradiant-03 {
    position: absolute;
    left: 15%;
    top: 360%;

    background: rgba(2, 172, 215, 0.411);
    filter: blur(150px);
    transform: rotate(90.2deg);
}

.infinite-gradient-text {
    background-image: linear-gradient(347deg, #0004ff, #124c50);
    background-clip: text;
    color: transparent;
    background-size: 200% 100%;
    animation: gradientText 3s linear infinite;
}
