.mobile-style-menu {
    background: rgba(255, 255, 255, 0.37);
    border-radius: 0px 0px 16px 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.7px);
    z-index: 100;
}

.dektop-style-menu {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.37);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.7px);
    -webkit-backdrop-filter: blur(6.7px);
    border: 1px solid rgba(255, 255, 255, 1);
}

.active {
    /* @apply sm:dark:bg-[#460000f0] w-full h-7 justify-center flex items-center text-center text-white; */
    background-color: #050046f0 !important;
    width: full !important;
    height: 28px !important;
    justify-content: center !important;
    display: flex !important;
    justify-items: center !important;
    text-align: center !important;
    color: white !important;
    padding: 0 10px !important;
    display: flex !important;
    justify-items: center;
}

.expand-the-menu {
    height: 350px;
}
