@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Signika+Negative:wght@400;600;700&display=swap');

@keyframes float {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

.header-font {
    font-family: 'Bebas Neue', sans-serif;
    font-family: 'Signika Negative', sans-serif;
}
