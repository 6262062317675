.bg-radial-gradient {
  background: radial-gradient(circle, #0400ff 0%, #0400ff 7%, #ffffff00 26%);
}

.infinite-gradient-text {
  background-image: linear-gradient(347deg, #0400ff, #124450);
  background-clip: text;
  color: transparent;
  background-size: 200% 100%;
  animation: gradientText 3s linear infinite;
}
/* 
.footer-grad {
    background-image: linear-gradient(180deg, #e7e7e700, #c0262d);
} */

.line-lg {
  line-height: 1.2 !important;
}
