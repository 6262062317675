@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Signika+Negative:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Black+Ops+One&family=Signika+Negative:wght@400;600;700&family=Varela+Round&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: 'Bebas Neue', sans-serif;
    font-family: 'Signika Negative', sans-serif; */
}

.main-body {
    background-color: rgb(255, 255, 255);
}

.header-font {
    font-family: 'Bebas Neue', sans-serif;
    font-family: 'Signika Negative', sans-serif;
}
