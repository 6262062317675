@keyframes gradientText {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
}

.infinite-gradient-text {
    background-image: linear-gradient(347deg, #0400ff, #124450);
    background-clip: text;
    color: transparent;
    background-size: 200% 100%;
    animation: gradientText 3s linear infinite;
}
